define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/utilities", "discourse-common/helpers/d-icon", "discourse-common/lib/later", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _utilities, _dIcon, _later, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubHandle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "copied", [_tracking.tracked], function () {
      return false;
    }))();
    #copied = (() => (dt7948.i(this, "copied"), void 0))();
    copy() {
      (0, _utilities.clipboardCopy)(this.args.actor.handle);
      this.copied = true;
      (0, _later.default)(() => {
        this.copied = false;
      }, 2000);
    }
    static #_4 = (() => dt7948.n(this.prototype, "copy", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-handle">
          <div class="activity-pub-handle-contents">
            <span class="handle">{{@actor.handle}}</span>
            {{#if @actor.url}}
              <a
                href={{@actor.url}}
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-icon no-text"
              >{{icon "external-link-alt"}}</a>
            {{/if}}
            {{#if this.copied}}
              <DButton @icon="copy" @label="ip_lookup.copied" class="btn-hover" />
            {{else}}
              <DButton @action={{this.copy}} @icon="copy" class="no-text" />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "yVVETTR9",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-handle\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-handle-contents\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"handle\"],[12],[1,[30,1,[\"handle\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"url\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"btn btn-icon no-text\"],[12],[1,[28,[32,0],[\"external-link-alt\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"copied\"]],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-hover\"]],[[\"@icon\",\"@label\"],[\"copy\",\"ip_lookup.copied\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],[[24,0,\"no-text\"]],[[\"@action\",\"@icon\"],[[30,0,[\"copy\"]],\"copy\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle.js",
      "scope": () => [_dIcon.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubHandle;
});